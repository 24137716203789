
.dialog-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  width: 100%;
}

.document-add-container {
  margin-top: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff0099;
  margin: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.file-input-label {
  display: inline-block;
  cursor: pointer;
  background-color: #ff0099; /* Background color set to #ff0099 */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.file-input-label:hover {
  background-color: #cc0077; /* Hover background color */
}


.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.image{
  max-width: 130px;
  max-height: 80px;
}

.title-text{
  font-size: 18px !important;
}


@media (min-width: 768px) {
  .card-container {
    width: 75%;
    margin: 0 auto; 
    padding: 40px 80px;
  }
}
@keyframes rotate1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.ring:nth-child(1) {
  border-bottom-width: 8px;
  border-color: rgb(255, 0, 255);
  animation: rotate1 2s linear infinite;
}

.ring:nth-child(2) {
  border-bottom-width: 8px;
  border-color: rgb(255, 0, 255);
  animation: rotate2 2s linear infinite;
}

.ring:nth-child(3) {
  border-bottom-width: 8px;
  border-color: rgb(255, 0, 255);
  animation: rotate3 2s linear infinite ;
}

.container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ring{
  width: 200px;
  height: 200px;
  border: 0px solid rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
}

.loading-text {
  position: absolute;
  font-size: 50px;
  color: rgb(2, 2, 2);
  animation: colorChange 6s linear infinite;
}


/* .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FF3D00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}  */