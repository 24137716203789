/* EnrollmentValidationTable.css */

.card {
  padding: 16px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.attachment {
  max-width: 300px;
  margin: auto;
  height: auto;
  align-items: center;
  margin-bottom: 15px;
}

.personal-info,
.business-info,
.controller-info {
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.approveButton {
  color: white;
  margin-right: 8px;
}

.denyButton {
  color: white;
}

.doc-image{
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: row;
}

.doc-image-container{
  margin: auto;
  text-align: center;
}

.back-button{
  margin: auto;
  margin-top: 10px;
}