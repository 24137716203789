.header-title {
  margin-left: 0;
  justify-self: left;
  text-align: left;
  font-family: "Lexend Deca Black", sans-serif;
  color: #ff0099 !important;
  font-size: 3vw;
  margin-top: 20vh;
}

.font-titi {
  font-family: " Titillium Web", sans-serif;
}

.menu-label {
  color: rgb(61, 59, 59);
  font-weight: normal;
  font-size: 16px;
}

.description {
  margin-left: 0;
  margin-top: 25px;
  justify-self: left;
  text-align: left !important;
  font-weight: 500 !important;
  font-size: 1vw;
  color: black !important;
  max-width: 30vw;
  line-height: 1.4vw !important;
  min-height: 350px;
}

.icons {
  height: 20px;
  width: 100%;
}

.img-icons {
  width: 100px !important;
  height: 100px !important;
}

.body {
  background-color: rgba(245, 243, 243, 0);
  padding-bottom: 50px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .body {
    padding: 20px; /* Adjust the padding to give some space on the sides */
    background-size: cover; /* Ensure the background covers the entire area */
    background-position: center; /* Center the background image */
  }
  .header-title {
    font-size: 48px !important; /* adjust according to your preference */
    margin-top: 5vh; /* reduce the top margin */
    color: #000; /* Change to a darker color for better contrast */
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 55px !important;
    margin: auto;
  }

  .overlay-content {
    background-color: rgba(255, 255, 255, 0.7); /* white with 60% opacity */
    padding: 20px;
    border-radius: 10px; /* optional: for rounded edges */
  }

  .description {
    font-size: 16px; /* Adjust the font size for mobile */
    line-height: 24px; /* Adjust the line-height for better readability */
    max-width: 100%; /* Allow the description to take the full width */
    min-height: auto; /* Remove the fixed height */
    margin-top: 15px;
    line-height: 25px !important;
    text-align: center !important;
    padding: 0 15px !important;
  }

  .header-button {
    color: black;
  }

  .responsive-text {
    width: 100vw !important;
    margin: auto;
    text-align: center;
    padding: 0 10px !important;
  }

  .img-home-section {
    left: -20px;
  }
  .descrition-text-section {
    width: 275px !important;
    margin-left: -10px;
  }

  .img-icons {
    width: 100%;
    height: auto;
  }
  .custom-card-item {
    border-radius: 20px;
    box-shadow: 7px 20px 20px rgb(210, 227, 244);
    padding: 10px;
    width: 400px !important;
  }

.max-screen {
  max-width: 100vw !important;
}

.items-container{
  flex-direction: column !important;
}

  nav,
  .menu-label,
  h3,
  .description,
  button {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
  }
}
