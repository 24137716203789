.login-form{
  max-width: 70vw;
  margin: auto;
  margin-top: 35px;
}

.auth-title{
  margin-top: 25px;
}

.pagination{
  margin: auto;
  text-align: center;
  margin-top: 15px;
}

.buttons{
  margin: 5px;
}